import React from 'react';
import './App.css';

//Material UI components
import { Container } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//Custom Components
import RainHead from './components/RainHead';
import RainTitle from './components/RainTitle';
import BreadCrumb from './components/Breadcrumb';

//images for head
import { Helmet } from 'react-helmet';
import RainLogo from './img/NNUH.svg';
//import Context provider for State
import StepContextProvider from './components/Contexts/StepContext';
import InputContextProvider from './components/Contexts/UserInputContext';
import PDFContextProvider from './components/Contexts/PDFContext';

//custom theme
const Theme = createMuiTheme({
  palette: {
    primary: {
      light: '#41B6E6',
      main: '#005EB8',
      dark: '#00A9CE',
    },
    secondary: {
      main: '#00A9CE',
    },
  },

  shape: {
    borderRadius: 0,
  },
});

//custom styles for container

const useStyles = makeStyles({
  root: {
    maxWidth: '1070px',
  },
});

function App(props) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={Theme}>
      <InputContextProvider>
        <StepContextProvider>
          <PDFContextProvider>
              <Box marginTop={4}>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>Covid 19 Risk Assessment</title>
                  <link href="" />
                </Helmet>
                <Container className={classes.root}>
                  <RainHead corporateImage={null} image={RainLogo} />

                    <Box >
                    <p className='unavailableParagraph'>This service is no longer active at your trust, please contact your manager if you have any question.</p>
                    </Box>
                </Container>
              </Box>
          </PDFContextProvider>
        </StepContextProvider>
      </InputContextProvider>
    </ThemeProvider>
  );
}

export default App;
