import React, { createContext, useState } from 'react';

export const InputContext = createContext();

const InputContextProvider = (props) => {
  const [standardInput, setStandardInput] = useState('');
  const [yesNo, setYesNo] = useState('');
  const [inputDate, setInputDate] = useState('');

  /**
   * TODO: Put these three into their own context
   * ? DropDownContext.js
   */
  const [dropDown, setDropDown] = useState('');
  const [canAddValue, setCanAddValue] = useState(null);
  const [multiSelect, setMultiSelect] = useState([]);
  const [menuItem, setMenuItem] = useState('');
  const [knownAnswers, setKnownAnswers] = useState([]);
  const [outcome, setOutcome] = useState();
  const [questions, setQuestions] = useState();

  return (
    <InputContext.Provider
      value={{
        standardInput,
        setStandardInput,
        yesNo,
        setYesNo,
        dropDown,
        setDropDown,
        canAddValue,
        setCanAddValue,
        multiSelect,
        setMultiSelect,
        menuItem,
        setMenuItem,
        knownAnswers,
        setKnownAnswers,
        inputDate,
        setInputDate,
        outcome,
        setOutcome,
        questions,
        setQuestions,
      }}
    >
      {props.children}
    </InputContext.Provider>
  );
};

export default InputContextProvider;
