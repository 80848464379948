import React, { createContext, useContext, useState } from 'react';
import { InputContext } from './UserInputContext';
export const PDFContext = createContext();

let PDFObj = {
  //initialise all of the values you wish to have in your PDF here with '' as a value
  emailPersonal: '',
  emailManager: '',
  //intro text
  introBoldText: '',
  userUnhappy: '',
  //initial bullets
  nameBullet: '',
  DOBBullet: '',
  phoneBullet: '',
  emailBullet: '',
  roleTypeBullet: '',
  departmentBullet: '',
  BAMEBullet: '',
  //Section 1
  outcome: '',
  outcomeMetaData: '',
  //Section 2
  employability: false,
  medicalConditions: '',
  //Section 3
  userUnhappy: '',
  userUnhappyTrue: '',
  personalProtective: '',
  pregnancyStatus: '',
  publicTransport: '',
  sharedAccomodation: '',
  personalStatus: '',
  boss: 'line manager',
  //Section 4
  metaData: '',

  //Section 5
  underlyingHealth: '',
  transcript: '',
  BMI: '',
  //additional advice
  BMINear30: '',
  pregnantConditionalLessThan28: '',
  pregnancyUnderlyingHealth: '',
  pregnancy: '',
  asthma: '',
  noAdvice: '',
  //additional advice sec 1/2
  uncontrolledAsthma: false,
  uncontrolledAsthmaMeta: false,
  over70: false,
  over70Meta: false,
  raisedBMI: false,
  raisedBMIMeta: false,
  asthmaBAME: false,
  asthmaBAMEMeta: false,
  coMorbidity: false,
  coMorbidityMeta: false,
  medicalConditionsBame: false,
  medicalConditionsBameMeta: false,
  maleOver50: false,
  maleOver50Meta: false,
  over60: false,
  over60Meta: false,
};

//Possible risk due to BMI of 30 or over and a co-morbidity
/*Increased risk identified due to disclosed risk factors or medical conditions and being BAME
Possible risk due to being male and aged over 50 and with a co-morbidity
Increased risk due to age over 60 and another co-factor
Higher risk factor identified due to age over 70
Higher risk factor identified due to raised BMI
Possible increased risk due to Asthma and being BAME*/

let BMICalc = {
  m: '',
  kg: '',
  feet: '',
  inches: '',
  stone: '',
  pounds: '',
};

const PDFContextProvider = (props) => {
  const { outcome } = useContext(InputContext);

  const [transcriptObj, setTranscriptObj] = useState({});

  //logic for setting values of PDFData//

  const handlePDFData = (values, question, final) => {
    //handling data for PDF after the outcome
    if (final === true) {
      values[0].map((outcomeIt, index) => {
        if (index === 0) {
          PDFObj.outcome = outcomeIt.object;
          PDFObj.outcomeMetaData = outcomeIt.objectMetadata.en[0].data;
        } else {
          //switch here to identify all other outcomes

          switch (outcomeIt.object) {
            case 'Possible risk due to BMI of 30 or over and a co-morbidity':
              PDFObj.coMorbidity = outcomeIt.object;
              PDFObj.coMorbidityMeta = outcomeIt.objectMetadata.en[0].data;
              break;
            case 'Increased risk identified due to disclosed risk factors or medical conditions and being BAME':
              PDFObj.medicalConditionsBame = outcomeIt.object;
              PDFObj.medicalConditionsBameMeta =
                outcomeIt.objectMetadata.en[0].data;
              break;
            case 'Possible risk due to being male and aged over 50 and with a co-morbidity':
              PDFObj.maleOver50 = outcomeIt.object;
              PDFObj.maleOver50Meta = outcomeIt.objectMetadata.en[0].data;
              break;
            case 'Increased risk due to age over 60 and another co-factor':
              PDFObj.over60 = outcomeIt.object;
              PDFObj.over60Meta = outcomeIt.objectMetadata.en[0].data;
              break;
            case 'Higher risk factor identified due to age over 70':
              PDFObj.over70 = outcomeIt.object;
              PDFObj.over70Meta = outcomeIt.objectMetadata.en[0].data;
              break;
            case 'Higher risk factor identified due to raised BMI':
              PDFObj.raisedBMI = outcomeIt.object;
              PDFObj.raisedBMIMeta = outcomeIt.objectMetadata.en[0].data;
              break;
            case 'Possible increased risk due to Asthma and being BAME':
              PDFObj.asthmaBAME = outcomeIt.object;
              PDFObj.asthmaBAMEMeta = outcomeIt.objectMetadata.en[0].data;
              break;
            case 'Uncontrolled Asthma Advice':
              PDFObj.uncontrolledAsthma = outcomeIt.object;
              PDFObj.uncontrolledAsthmaMeta =
                outcomeIt.objectMetadata.en[0].data;
              break;
            case 'Advice for pregnant healthcare workers after 28 weeks gestation, or with an underlying health condition at any stage of pregnancy':
              PDFObj.pregnancyUnderlyingHealth = true;
              break;
            case 'Advice for pregnant healthcare workers before 28 weeks gestation':
              PDFObj.pregnantConditionalLessThan28 = true;
              break;
            case 'Likely able to be employed in full role without limitations or with existing agreed limitations':
              PDFObj.employability = outcomeIt.object;

              break;
            case 'Likely able to be employed in full role without limitations, requires Occupational Health Assessment for further advice due to medical issue(s) after starting role':
              PDFObj.employability = outcomeIt.object;

              break;
            case 'Likely able to be employed in role with some limitations, requires Occupational Health Assessment for further advice due to medical issue(s) after starting role. Manager should conduct a risk assessment':
              PDFObj.employability = outcomeIt.object;

              break;
            case 'The individual has indicated that they have an underlying condition that may not be relevant to COVID risk assessment but they believe it may have an impact on their ability to carry out their role. There they have asked for a referral to be sent to occupational health to discuss this.':
              PDFObj.employability = outcomeIt.object;

              break;
            case 'Likely able to be employed in role with some limitations, requires Occupational Health Assessment for further advice due to medical issue(s) prior to starting role':
              PDFObj.employability = outcomeIt.object;

              break;
          }
        }
      });
      return;
    }
    //handling data from initial inject
    if (question === undefined) {
      //All data capture fields
      console.log('values phone', values.phone);
      PDFObj.emailPersonal = values.emailPersonal;
      PDFObj.emailManager = `${values.emailManager}@nnuh.nhs.uk`;
      PDFObj.nameBullet = `${values.firstName} ${values.lastName}`;
      PDFObj.DOBBullet = values.date;
      PDFObj.phoneBullet = values.phone;
      PDFObj.emailBullet = values.emailPersonal;
      PDFObj.roleTypeBullet = values.typeOfRole;

      //checking if second option on personalStatus question was selected on
      if (
        values.reasonForAssessment ===
        'I am completing this risk assessment about myself and my own risk status but have concerns about a household member or someone I have caring responsibilities for and would like advice'
      ) {
        PDFObj.personalStatus = true;
      }
    } else {
      //handling populating the transcript
      let currentQuestion = question.prompt;
      let history = transcriptObj;
      history[currentQuestion] = values;
      setTranscriptObj(history);
      //switch to handle all subsequent questions

      switch (question.relationship) {
        case 'department 1':
          PDFObj.departmentBullet = values;
          break;
        //TODO: check this item.
        case 'worker has ethnicity':
          PDFObj.BAMEBullet = values;

          break;

        //? BMI CALCULATION MOVE TO RB
        case 'worker has preferred unit':
          //reset units to cover edge case that we collect mixed units.
          BMICalc.m = '';
          BMICalc.kg = '';
          BMICalc.feet = '';
          BMICalc.inches = '';
          BMICalc.stone = '';
          BMICalc.pounds = '';
          break;
        case 'has height in centimetres':
          BMICalc.m = Number(values / 100);

          break;
        case 'has weight in kilograms':
          BMICalc.kg = Number(values);
          break;
        case 'has height in feet':
          BMICalc.feet = Number(values) * 12;

          break;
        case 'has height in inches':
          BMICalc.inches = Number(values);

          break;
        case 'has weight in stone':
          BMICalc.stone = Number(values) * 14;

          break;
        case 'has weight in pounds':
          BMICalc.pounds = Number(values);

        case 'has weight in pounds':
          break;
        case 'worker has age':
          if (BMICalc.m !== '' && BMICalc.kg !== '') {
            // calculate BMI in metric

            let BMIAccurate = BMICalc.kg / (BMICalc.m * BMICalc.m);
            PDFObj.BMI = Math.round(BMICalc.kg / (BMICalc.m * BMICalc.m));

            if (BMIAccurate >= 28 && BMIAccurate < 30) {
              PDFObj.BMINear30 = true;
            }
          } else if (
            BMICalc.feet !== '' &&
            BMICalc.inches !== '' &&
            BMICalc.stone !== '' &&
            BMICalc.pounds !== ''
          ) {
            //calculate BMI in imperial
            let totalHeight = BMICalc.feet + BMICalc.inches;
            let totalWeight = BMICalc.stone + BMICalc.pounds;

            let BMIAccurate = (totalWeight / (totalHeight * totalHeight)) * 703;
            PDFObj.BMI = Math.round(
              (totalWeight / (totalHeight * totalHeight)) * 703
            );

            if (BMIAccurate >= 28 && BMIAccurate < 30) {
              PDFObj.BMINear30 = true;
            }
          } else {
            return;
          }
          break;
        case 'worker has a public transport statement':
          if (values === 'true') {
            PDFObj.publicTransport = `Any risk assessment will need to look at whether or not it is possible to mitigate risk associated with
            employer provided transport such as a minibus/ park and ride e.g. If this is COVID SECURE.
            Further information from you may be required to consider this risk. Public transport use by staff who
            have previously been shielding will need to be carefully considered. In some circumstances
            continuation of home working may be required if this risk cannot be mitigated as no mitigations/
            alternatives can be identified. E.g. Someone with a respiratory condition who is unable to wear a
            mask and feels they do not wish to travel on public transport or someone with a persistent cough
            unrelated to COVID-19 who may find it difficult to travel on public transport.`;
          } else {
            PDFObj.publicTransport =
              'There are no public transport related considerations.';
          }

          break;
        case 'worker has shared accommodation statement':
          if (values === 'true') {
            PDFObj.sharedAccomodation = true;
          } else {
            PDFObj.sharedAccomodation = false;
          }

          break;
        case 'worker has underlying health condition statement':
          if (values === 'true') {
            PDFObj.medicalConditions =
              'The individual has reported that they have other issues related to a medical condition.';
            PDFObj.underlyingHealth = true;
          } else {
            PDFObj.medicalConditions =
              'The individual has reported that they do not have other medical conditions.';
          }

          break;
        case 'worker has PPE Risk':
          switch (values) {
            case `I am not aware of any reason medical or otherwise why I can't wear any PPE and have completed all relevant training.`:
              PDFObj.personalProtective =
                'Able to wear Personal Protective Equipment without restriction';

              break;
            case `I am not aware of any reason medical or otherwise why I can't wear any PPE but have not completed all relevant training.`:
              PDFObj.personalProtective =
                'Able to wear Personal Protective Equipment without restriction - states requires training';

              break;
            case `I am unable to wear certain type of PPE for cultural or religious reasons and wish to discuss this with my manager.`:
              PDFObj.personalProtective =
                'Unable to wear certain types of PPE due to cultural or religious reasons -requires assessment by line manager in accordance with relevant trust policy';

              break;
            case `I do not wish to comply with trust policies on facial hair and wish to discuss this with my manager`:
              PDFObj.personalProtective =
                'Does not wish to comply with facial hair policy or other person reason preventing wearing of PPE -requires assessment by line manager in accordance with relevant trust policy';

              break;
            case `I am unable to wear a mask or certain types of mask in the short term due to a medical issue and will discuss this further with occupational health`:
              PDFObj.personalProtective =
                'Temporarily unable to wear full PPE- requires an Occupational Health Assessment to provide further advice due to disclosed medical issue(s).';

              break;
            case `I am unable to wear a mask or certain types of mask in the long term due to a medical issue and will discuss this further with occupational health`:
              PDFObj.personalProtective =
                'Permanently unable to wear full PPE- requires an Occupational Health Assessment to provide further advice due to disclosed medical issue(s).';

              break;
            case `PPE is not required for my current role and I am not aware of any reason that I would not be able to wear PPE if the government rules change`:
              PDFObj.personalProtective =
                'PPE is not required for role (unaware of any reason unable to wear PPE)';

              break;
          }

          break;
        //pregnancy logic
        case 'worker has pregnancy statement':
          if (values === 'true') {
            PDFObj.pregnancy = true;
          } else {
            PDFObj.pregnancyStatus = 'The Individual is not pregnant.';
          }
          if (PDFObj.underlyingHealth === true && PDFObj.pregnancy === true) {
            PDFObj.pregnancyUnderlyingHealth = true;
          }
        case 'worker has age of pregnancy':
          if (values < 1 || values === 'false') {
            PDFObj.pregnancyStatus = 'The Individual is not pregnant.';
          } else {
            PDFObj.pregnancyStatus = `Individual is ${values} weeks pregnant`;
          }

          break;
        case 'worker has asthma statement':
          if (values === 'true') {
            PDFObj.asthma = true;
          }
          break;
      }
    }

    //handling toggle button outcome on endscreen
  };

  const handleToggleResultPDF = (values) => {
    if (
      PDFObj.pregnancy === '' &&
      PDFObj.pregnantConditionalLessThan28 === '' &&
      PDFObj.pregnancyUnderlyingHealth === '' &&
      PDFObj.asthma === '' &&
      PDFObj.BMINear30 === ''
    ) {
      PDFObj.noAdvice = 'There is no associated guidance or advice to provide.';
    }
    if (values === true) {
      PDFObj.userUnhappy =
        'Individual is comfortable with the result given by the consultation';
    } else {
      PDFObj.userUnhappyTrue = true;
      PDFObj.userUnhappy =
        'Individual is not comfortable with the results given, a discussion is required with their manager';
    }
  };

  const handleTranscriptPopulation = () => {
    // creating a clean string and attaching it to the PDFObj
    let fullString;

    for (const [key, value] of Object.entries(transcriptObj)) {
      fullString = `${fullString}\n\n Question: ${key}: \n\n Answer: ${value}`;
    }
    //clean string
    fullString = fullString.replace('undefined', '');
    //set string

    PDFObj.transcript = fullString;
  };

  const endQueries = (furtherQueryOutcome) => {
    if (furtherQueryOutcome !== undefined) {
      //adding items to PDFObj if you choose to run queries after the main query
    }
  };

  return (
    <PDFContext.Provider
      value={{
        PDFObj,
        transcriptObj,
        handlePDFData,
        endQueries,
        handleToggleResultPDF,
        handleTranscriptPopulation,
      }}
    >
      {props.children}
    </PDFContext.Provider>
  );
};

export default PDFContextProvider;
