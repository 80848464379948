import React, { useContext } from 'react';
//Formik
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
//Material UI
import Typography from '@material-ui/core/Typography';
//Custom imports
import { CardContent } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { StepContext } from './Contexts/StepContext';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(1),
  },
}));

export default function BreadCrumb() {
  const { step, getSteps, getStepContent } = useContext(StepContext);
  const steps = getSteps();

  const classes = useStyles();

  return (
    <React.Fragment>
      {step === steps.length - 1 ? (
        <CardContent>
          <Typography className={classes.instructions}>
            All steps completed - you&apos;re finished
          </Typography>
        </CardContent>
      ) : (
        <CardContent>
          <Typography className={classes.instructions}>
            {getStepContent(step)}
          </Typography>
        </CardContent>
      )}
      <Stepper activeStep={step} >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          /*  if (isStepSkipped(index)) {
            stepProps.completed = false;
          }*/
          return (
            <Step key={label} {...stepProps} >
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </React.Fragment>
  );
}
