import React from 'react';

export default function RainHead(props) {
  const { image } = props;
  return (
    <React.Fragment>
      <img
        src={image}
        style={{ display: 'inLineBlock', width: '100%' }}
        alt="Powered By Rainbird"
      ></img>
    </React.Fragment>
  );
}
